import Headroom from "headroom.js";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from "vh-check";

const test = vhCheck({
    cssVarName: "vh-offset"
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
    offset: 30
});

/* Burger menu dropdown */
const customSlideDropdown = element =>
    (element.style.height = `${element.scrollHeight}px`);
const customSlideUp = element => (element.style.height = "0px");

let customSlideItems = document.querySelectorAll(
    "#burger-menu .header-nav__item--icon"
);
customSlideItems.forEach(item => {
    let currentDropdown = item.parentNode.querySelector(
        ".header-nav__dropdown"
    );

    item.addEventListener("click", function() {
        this.parentNode.classList.toggle("open");
        currentDropdown.classList.toggle("open");
    });
});

document.addEventListener("click", function(e) {
    if (e.target && e.target.classList.contains("form-status")) {
        document.querySelector(".form-status").classList.add("hide");
    }
});

//BURGER MENU
const header = document.getElementById("header-wrapper");
const burgerMenu = document.getElementById("burger-menu");
const burgerMenuOpen = document.getElementById("burger-menu-open");
const burgerMenuOverlay = document.getElementById("burger-overlay");
const headroom = new Headroom(header, {
    offset: document.getElementById("header-wrapper").offsetHeight / 2
});
headroom.init();

burgerMenuOpen.addEventListener("click", () => {
    burgerMenu.classList.toggle("open");
    burgerMenuOpen.classList.toggle("open");
    burgerMenuOverlay.classList.toggle("open");
});

burgerMenuOverlay.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOpen.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
});

var scrollStart = function(event) {
    burgerMenu.classList.remove("open");
    burgerMenuOpen.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
};

// Listen for scroll events
document.addEventListener("scrollStart", scrollStart, false);

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onscroll = function() {
    scrollUpToggle();
};

document.querySelectorAll(".js-contact-form").forEach(element => {
    element.addEventListener("submit", function(e) {
        e.preventDefault();
        submitContactForm(e);
    });
});

// Contact form send
function submitContactForm(element) {
    let url = element.target.dataset.url;
    let formButton = document.querySelector(".form__button");
    formButton.disabled = true;

    // Append the form status
    let formStatus = document.createElement("div");
    formStatus.setAttribute("class", "form-status");

    let formData = {};
    let form = element.target;
    let data = new FormData(form);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }

    fetch(url, {
        method: "POST",
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content")
        },
        body: JSON.stringify(formData)
    })
        .then(response => response.json())
        .then(data => {
            form.appendChild(formStatus);
            if (data.status === 1) {
                formStatus.className += " alert-success";
                formStatus.innerHTML = data.msg ? data.msg : "?";
                form.reset();
            } else {
                let obj = data.msg;
                let message = "";
                if (typeof obj === "object") {
                    Object.keys(obj).map(function(key) {
                        message += obj[key] + "\n";
                    });
                } else {
                    message = obj;
                }
                formStatus.className += " alert-danger";
                formStatus.innerHTML = message ? message : "?";
            }

            setTimeout(function() {
                formStatus.remove();
            }, 3000);
        });
}
